.page-not-found {
    max-width: 1100px;
    min-height: 450px;
    margin:0 auto;
    padding: 40px;
    display:flex;
    text-align: center;
    background-color: #ffffff;
}

.page-not-found .title {
    font-size: 50px;
    letter-spacing: 10px;
    margin-bottom: 10px;
}

.page-not-found .desc {
    font-size: 20px;
    margin-bottom: 20px;
}

.go-back-btn {
    min-width: 160px;
}